
import { Component, Vue } from 'vue-property-decorator';
import BlueBorderedButton from '@/components/buttons/BlueBordered.vue';

@Component({
  components: { BlueBorderedButton }
})
export default class EmptyOffers extends Vue {
  handleClick(): void {
    console.log('clicked');
  }
}
